.Settings{
    min-height: 100vh;
    background-size: cover;
    font-family: "Metropolis", sans-serif;
    font-size: medium;
    padding: "20px"
}

.Settings svg{
    width: auto !important;
    height: auto !important;
}

.ql-toolbar.ql-snow {
  background-color: #ffffff;
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    position: absolute;
    margin-top: -9px !important;
    right: 0 !important;
    top: 50% !important;
    width: 18px !important;
}

.ql-editor{
  background-color: azure;
}

h1{
    font-size: 3rem;
    color: #000000;
    margin-bottom: 20px;
    align-items: center;
}

h3{
    font-size: 1rem !important;
    color: #000000;
    margin-bottom: 20px;
    align-items: center;
}

#root > div > div > div > form > div:nth-child(1) > input[type=text]{
    color: #000 !important;
}

.status{
    opacity: 1.7;
    width: 320px;
    padding: 3px;
    border-radius: 15px;
    background-color: #adbd8e;
    padding-bottom: 20px;
}



.news-form {
  padding: 20px;
  background-color: #3a87d5;
  opacity: 0.7;
  color: black;
  font-family: 'Metropolis';
  font-weight: 700;
  border: black;
  border-style: ridge;
  width: 50vw;
}

.news-form p{
  color: black;
}

.news-article {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 40vw;
}

.news-image {
  width: 35vw;
  height: 29vh;
  object-fit: contain;
  margin-right: 20px;
}

.news-item{
  margin-bottom: 3vh;
  background-color: #75abe1;
  padding: 20px;
  text-align: left;
}

.news-list{
  padding: 44px;
  width: 44vw;
}

.news-content {
  flex: 1;
  margin-top: 0.5em;
  padding: 20px;
}

.news-date {
  font-size: 0.8em;
  color: #888;
}

textarea {
  resize: vertical;
  width: 50%;
  height: 20vh;
  background: #fff;
  border: 1px solid #ccc;
  color: #000 !important;
  border-radius: .125rem;
  padding: .25rem .5rem;
  height: 270px !important;
}

.Settings-h2{
  font-size: 2rem;
  color: #fff;
  margin-bottom: 20px;
}


@media screen and (max-width: 1440px) {
  .Settings {
    margin-top: 10vh;
  }
  .ql-editor{
    height: 18vh !important;
    background-color: azure;
  }
}
@media screen and (max-width: 1024px) {
  .news-list {
    padding: 44px;
    width: 61vw;
}
}

  @media screen and (max-width: 768px) {
    .form, form {
        display: contents;
    }
    button{
        display: flex !important;
    }
    .news-image {
      margin-top: 8vh;
      width: auto;
  }
    input, select, textarea {
        display: block;
    }
    .Settings {
      margin-top: 10vh;
    }
    .ql-editor{
    height: 18vh !important;
    background-color: aliceblue;
    }
  }
@media screen and (max-width: 425px){
  .news-form {
    background-color: #d4d4d4;
    opacity: 0.7;
    width: 158vw;
}
.news-list {
  width: 150vw;
}
.status {
  width: 140vw;
  padding: 3px;
  border-radius: 15px;
}
}
