/* Ensure the folder content has its own space and is properly indented */
.folder-search-list-item ul {
    padding-left: 20px; /* Indent the folder content */
    margin-top: 10px; /* Add space above the folder content */
    margin-bottom: 10px; /* Add space below the folder content */
    list-style-type: none; /* Remove default list style */
  }
  
  .folder-search-list-item ul li {
    margin-bottom: 10px; /* Add space between each file in the folder */
  }

  #root > div.search-main-div > div > ol > li > div > p > svg.svg-inline--fa.fa-folder.folder{
    height: 1em !important;
  }

  #root > div.search-main-div > div > ol > li> div > p> svg.svg-inline--fa.fa-folder-open.folder{
    height: 1em !important;
  }

  .search-list-item {
    border-width: 1px;
    border-radius: 5px;
    margin: 5px;
    border-color: #72ffa666;
    width: 60vw;
    padding-inline: 20px;
    list-style: none !important;
    width: 50vw !important;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    position: relative;
    height: 2em;
  }
  
  .list-info {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.delete{
    height: 1em !important;
}

.folder-list-info{
    display: flex;
    justify-content: space-between;
    background-color: #d1dda2;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 4px;
}
  
  .list-info p {
    margin: 0;
    padding: 0;
  }
  
  .folder-search-list-item {
    border-width: 1px;
    border-radius: 5px;
    margin: 5px;
    border-color: #72ffa666;
    width: 60vw;
    padding-inline: 20px;
    list-style: none !important;
    width: 50vw !important;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    z-index: 9999 !important;
    position: relative;
    height: 2em;
}
  
  li:hover {
    background-color: rgba(0, 255, 255, 0.13);
    transition: 0.1s;
  }
  
  @media screen and (max-width: 1440px) {
    /* Search Categories  */
    .search-main-div {
      margin-top: 10vh;
    }
  }
  
  @media screen and (max-width: 768px) {
    #root > div > div > div:nth-child(1) {
      margin-top: 10vh;
    }
    button {
      display: none;
    }
    .search-list-item {
      width: auto !important;  
  }
  }
  
  @media screen and (max-width: 425px) {
    .search-main-div {
      margin-top: 19vh;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    /* CSS code for screens between 768px and 1024px */
  }
  
  @media screen and (min-width: 1024px) {
    /* CSS code for screens larger than 1024px */
  }