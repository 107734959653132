.contacts-text-section {
    display: flex;
    margin-bottom: 20px;
    margin-left: 26vw;
    margin-right: 26vw;
    text-align: justify;
    line-height: normal;
    margin-top: 91px;
    flex-direction: column;
    align-items: left;
}

.contacts-text-section h2 {
    font-size: x-large !important;
    margin-bottom: 2vh;
}
.contacts-text-section a {
    font-size: x-large;
    margin-bottom: 5vh;
}

.mailto {
    font-size: larger !important;
}
