#root > footer > section.d-flex.justify-content-center.justify-content-lg-between.p-4.border-bottom{
    
    justify-content: center !important;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

html, body {
    height: 100%;
    margin: 0;
  }

#Footer{
    margin-top: 100vh !important;
}


#root > footer > section:nth-child(1) > div > div > div{
    text-align: left;
}
