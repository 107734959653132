@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

svg{
  width: 420px !important;
  height: 420px !important;
}

.ws-text-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Metropolis', sans-serif;
  font-weight: 500;
  font-size: larger;
  color: #333;
  animation: fadeIn 2s ease-in-out;
}

.ws-header {
  background-color: #6cadef;
  color: white;
  padding: 20px;
  text-align: center;
  height: 8vh;
  animation: fadeIn 2s ease-in-out;
}

.ws-img-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.WP-img {
  position: relative;
  margin-top: 10px;
  margin-bottom: 0px;
  border: none;
  height: 40vh;
}

.ws-text-box {
  flex: 1 1;
  padding-left: 20px;
  min-width: 50vw;
  padding-left: 10vw;
  margin-top: 5vh;
  margin-bottom: 20px; /* Added margin-bottom to ensure space */
  animation: fadeIn 2s ease-in-out;
}

.ws-text-box p {
  color: rgb(0, 0, 0);
  opacity: 1;
  background-color: #f0f9f2;
  padding: 20px;
  z-index: 1100;
  width: 40vw;
  margin-top: 1vh !important;
}

#root > div > main > section > div > div.ws-text-box > p {
  color: black;
  animation: fadeIn 2s ease-in-out;
}

.ws-content {
  flex: 1;
  padding: 20px;
  animation: fadeIn 2s ease-in-out;
  background-image: url(https://res.cloudinary.com/djunroohl/image/upload/v1726410687/tp244-bg1-08_iibjso.jpg) !important;
  background-repeat: repeat-y;
  background-size: cover;
  overflow-x: hidden;
  height: 101vh;
  animation: fadeIn 2s ease-in-out;
}

.ws-text-section {
  display: flex;
  margin-bottom: 20px;
  margin-left: 14vw;
  margin-right: 26vw;
  flex-wrap: wrap;
  justify-content: center;
  text-align: justify;
  line-height: normal;
  margin-top: 35px;
  animation: fadeIn 2s ease-in-out;
}

.ws-text-section h2 {
  color: #002538;
  text-align: justify;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: 'Metropolis', sans-serif;
  font-weight: 500;
  font-size: larger;
  animation: fadeIn 2s ease-in-out;
}

.WP-img {
  margin-top: 20px;
  margin-bottom: 50px;
}

@media screen and (max-width: 1440px) {
  .ws-text-box{
    z-index: 1100;
    margin-top: 49vh !important;
    position: absolute;
  }
  .ws-header {
    height: 12vh !important;
  }
}

@media screen and (max-width: 1440px){
  .ws-img-container {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  .svg-text{
    margin-top: 20vh !important;
  }
}

@media screen and (max-width: 1440px) and (max-height: 500px) {
  .svg-text {
    height: 10vh !important;
    margin-top: 60vh !important;
  }

}

@media screen and (max-width: 768px) {
  .ws-content {
    background-image: none !important;
  }
  #root > div > main > section > div > div.ws-text-box > p {
    color: black;
    animation: fadeIn 2s ease-in-out;
    width: 111%;
  }
  .ws-text-box p {
    margin-top: 8vh !important;
    width: 55vw;
}
  svg {
    width: 100% !important;
    height: 125% !important;
    z-index: 99;
  }
}

@media screen and (max-width: 425px){
  .ws-text-box p {
    width: 140vw;
}
}
