/* Clarity Design */
@import '~@cds/core/global.min.css';
@import '~@cds/core/styles/theme.dark.min.css';
@import '~@clr/ui/clr-ui.min.css';


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

footer {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}
