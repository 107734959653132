.hamburger-menu {
    position: relative;
    z-index: 999;
    background-color: #daf5c9;
  }

  .maste-logo-hamburger{
    display: flex;
    margin-right: 10vw;
  }
  
  .hamburger-icon {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .fa-solid, .fas {
    font-size: large;
    font-weight: 900;
}
  
  .menu {
    display: none;
    position: absolute;
    top: 67px;
    left: 0;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .menu.open {
    display: block;
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu ul li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .menu ul li a {
    text-decoration: none;
    color: black !important;
    display: block;
    font-size: x-large !important;
    width: 270px;
}
  
  .menu ul li a:hover {
    background-color: #f0f0f0;
  }

@media screen and (max-width: 768px){     
          .fa-solid, .fas {
            font-weight: 900;
            font-size: xxx-large;
        }
        .maste-logo-hamburger {   
          border: 5px inset #1c261347;
          border-radius: 18px;
          display: flex;
          height: 13vh;
          margin-right: 46vw;
          margin-top: 0px;
          max-width: -moz-fit-content;
          max-width: -webkit-fit-content;
          max-width: fit-content;  
        }
}

  @media screen and (max-width: 425px){
    .maste-logo-hamburger {
      border: 5px inset #1c261347;
        border-radius: 24px;
        display: flex;
        height: 18vh;
        margin-right: 47vw;
        margin-top: 0;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        width: 108vw;
    }

    .App-header {
        width: 107%;   
    }

    .hamburger-menu {
                min-width: 107%;
            }
        }
    
@media screen and (max-width: 375px) {
        .hamburger-menu {
            width: 100%;
        }
    }
