p{
    font-family: 'Metropolis', sans-serif !important;
    color: #000;
    font-size: medium !important;
    font-weight: 500 !important;
    margin-top: 96px !important;
}

.home-text{
  margin-top: 10vh !important;
}

.news-headline{
  font-family: 'Metropolis';
  margin-left: 12vw;
}

.news-section {
    margin-top: 10vh;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .news-article {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-left: 12vw;
    flex-wrap: wrap !important;

  }
  
  .news-image {
    width: 250px;
    height: 150px;
    object-fit: contain;
    margin-right: 20px;
  }
  
  .news-content {
    flex: 1;
  }
  
  .news-date {
    font-size: 0.8em;
    color: #888;
  }

  @media screen and (max-width: 480px) {
    p {
        font-size: x-large !important;
        padding: 30px !important;
        line-height: 30px !important;
    }
}